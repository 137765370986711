<template>
  <v-dialog
    v-model="abrirModalDetalleEspecifica"
    persistent
    
    >
    <v-card class="pa-8">
      <v-card-title class="d-flex justify-center mb-4">
        <v-icon class="mr-2">mdi-information-slab-circle</v-icon>
        Detalle de insumos
      </v-card-title>
      
      <v-divider class="mb-5" />

      <v-card-text>
        <div>
          <v-btn
            @click="eliminarSeleccionadosModal"
            :disabled="selectedItems.length === 0"
            v-if="archivoSubido"
            class="mb-5"
            outlined
            color
            >
            <v-icon class="mx-1">mdi-delete-sweep</v-icon>
            Eliminar seleccionados
          </v-btn>
          <v-data-table
            :headers="headersInsumos"
            :items="insumos.insumosPlain"
            :inhabilitar_paginacion="true"
            :tiene_paginacion="false"
            v-model="selectedItems"
            show-select
            >

            <template v-slot:[`item.obs`]="{ item }">
              {{ item.Ob?.codigo }} - {{ item.Ob?.nombre }}
            </template>

            <template v-slot:[`item.precio_unitario`]="{ item }">
              $
              {{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(item.precio_unitario)
              }}
            </template>

            <template v-slot:[`item.total`]="{ item }">
              $
              {{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(item.precio_unitario * item.SolicitudCompraDetalleObs[0].cantidad)
              }}
            </template>

            <template v-slot:[`item.asociacion_proceso`]="{ item }">
              {{ item.SolicitudCompraDetalleObs[0]?.id_proceso_obs ? 'Asociado al proceso de compra' : 'No asociado al proceso' }}
            </template>

            <template v-slot:[`item.asociacion_lote`]="{ item }">
              {{ item.SolicitudCompraDetalleObs[0]?.id_sub_proceso ? 'Asociado a un lote' : 'No asociado a ningún lote' }}
            </template>
          </v-data-table>
        </div>
      </v-card-text>
      
      <v-card-actions class="d-flex justify-center mt-6">
        <v-btn class="primary mr-4" @click.stop="limpiarYCerrar"
        >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>

    <ConfirmationDialogComponent
      :show="show_modal_eliminar"
      title="¿Está seguro que desea eliminar los insumos seleccionados?"
      message="De confirmarse la siguiente acción, los cambios son irreversibles."
      btnConfirmar="Confirmar"
      @close="show_modal_eliminar = false"
      @confirm="eliminarSeleccionados"
    />
  </v-dialog>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";

export default {
  components: {
    DataTableComponent,
    ConfirmationDialogComponent,
  },

  name: "modalDetalleEspecifica",
  props: {
    abrirModalDetalleEspecifica: {
      type: Boolean,
      default: false,
    },
    insumos: {
      type: Object,
      default: () => [],
    },
    archivoSubido: {
      type: Boolean,
      default: false,
    },
    idProceso: {
      type: String,
    },
  },

  data: () => ({
    selectedItems: [],
    // modal eliminar especificas
    show_modal_eliminar: false,
    headersInsumos: [
      {
        text: "OBS",
        value: "obs",
        align: "center",
      },
      {
        text: "Especificación",
        value: "observacion",
        align: "center",
      },
      {
        text: "Unidad de medida",
        value: "Presentacion.nombre",
        align: "center",
      },
      {
        text: "Cantidad solicitada",
        value: "SolicitudCompraDetalleObs[0].cantidad",
        align: "center",
      },
      {
        text: "Precio unitario ($)",
        value: "precio_unitario",
        align: "center",
      },
      {
        text: "Total ($)",
        value: "total",
        align: "center",
      },
      {
        text: "Estado de insumo",
        value: "EstadoSolicitudOb.nombre",
        align: "center",
      },
      {
        text: "Proceso de compra",
        value: "asociacion_proceso",
        align: "center",
      },
      {
        text: "Agrupador en lote",
        value: "asociacion_lote",
        align: "center",
      },
    ],
  }),

  computed: {

  },
  methods: {
    limpiarYCerrar() {
      this.clearInputs(); // Limpia los inputs
      this.$emit('cerrar'); // Emite el evento
    },
    clearInputs() {
      this.selectedItems = [];
    },

    eliminarSeleccionadosModal() {
      this.show_modal_eliminar = true;
    },
    async eliminarSeleccionados() {
      const seleccionadosIds = this.selectedItems.map(item => item.id);

      const { data, status } = await this.services.EliminarEspecificasProcesoServices.eliminarEspecificasProceso(
        this.idProceso,
        { especificas: seleccionadosIds },
      );
      if (status === 200) {
        this.temporalAlert({
          show: true,
          message: data.message,
          type: "success",
        });
        this.show_modal_eliminar = false;
        this.$emit('actualizarListaEspecifica');
      }
      this.selectedItems = [];
    },

  },

  created() {
  },
};
</script>
<template>
  <v-dialog v-model="abrirModalDocumentos" persistent max-width="1200">
    <v-card class="pa-8">
      <v-card-title class="d-flex justify-center mb-4">
        <v-icon class="mr-2">mdi-clipboard-file</v-icon>
          Documentos del contrato
      </v-card-title>
      <div>
        <data-table-component
        :headers="headers"
        :items="documentos"
        :inhabilitar_paginacion="true"
        :tiene_paginacion="false"
        >
          <template v-slot:[`item.fecha_emision`]="{ item }">
            {{ item.fecha_emision ? moment(item.fecha_emision).format("DD/MM/YYYY hh:mm a") : 'Fecha no registrada.' }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="abrirModalDeleteDocument(item)"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!archivoSubido"
                  >
                  mdi-delete-circle
                </v-icon>
              </template>
              <span> Eliminar documento </span>
            </v-tooltip>
          </template>
        </data-table-component>
      </div>
      <v-card-actions class="d-flex justify-center mt-6">
        <v-btn class="primary mr-4" @click.stop="$emit('cerrar')"
          >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
    
    <ConfirmationDialog
      :show="modal_eliminar"
      title="Desea eliminar el documento seleccionado?"
      message="El cambio es irreversible, eliminará el documento permanentemente"
      btnConfirmar="Continuar"
      @close="modal_eliminar = false"
      @confirm="eliminarDocumentoById"
    />
  </v-dialog>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import moment from "moment";
import modalComplementoEtapa from "./modalComplementoEtapa.vue";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";

export default {
  name: "modalDetalleEtapa",
  components: {
    DataTableComponent,
    modalComplementoEtapa,
    ConfirmationDialog,
  },

  props: {
    abrirModalDocumentos: {
      type: Boolean,
      default: false,
    },
    documentos: {
      type: Array,
      default: () => [],
    },
    archivoSubido: {
      type: Boolean,
      default: false,
    },
    idProceso: {
      type: Number,
    },
  },

  data: () => ({
    modal_eliminar: false,
    id_etapa_selected: null,
    headers: [
      {
        text: "Nombre de archivo",
        value: "nombre_archivo",
        align: "center",
      },
      {
        text: "Tipo de documento",
        value: "TipoDocumentoOrden.nombre",
        align: "center",
      },
      {
        text: "Fecha de carga o emisión",
        value: "fecha_emision",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
      },
    ],
  }),
  computed: {
    hasDataEtapa() {
      return this.documentos && this.documentos.length > 0;
    },
  },
  methods: {
    async abrirModalDeleteDocument(item) {
      this.id_etapa_selected = item;
      this.modal_eliminar = true;
    },

    async eliminarDocumentoById() {
      const id_documento = this.id_etapa_selected.id;
      const id_contrato = this.id_etapa_selected.id_contrato_orden;
      const { data } = await this.services.EliminarEtapasContratoServices.eliminarDocumentoContratoById(this.idProceso, id_documento, id_contrato);
      if (data) {
        this.temporalAlert({
          show: true,
          message: data.message,
          type: "success",
        });
        this.modal_eliminar = false;
        this.$emit('cerrarModalActualizarDocumento')
      }
    },
  },
};
</script>

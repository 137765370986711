<template>
  <section class="pa-2">
    <v-row class="d-flex justify-space-between">
      <v-col cols="12" md="6">
        <v-card class="pa-5" style="height: 30vh; overflow-y: auto">
          <v-row class="mt-4">
            <v-col cols="9">
              <v-text-field
                label="Correlativo de contrato"
                outlined
                rounded
                hide-details
                clearable
                v-model="filters.correlativo"
                prepend-icon="mdi-magnify"
              />
            </v-col>
            <v-col cols="3">
              <v-btn class="primary mt-2" @click="getContratoByCorrelativo()">
                Filtrar
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="9">
              <v-file-input
                v-model="form.file"
                outlined
                rounded
                accept=".pdf"
                @change="validateFile"
                :label="
                  subido
                    ? 'Puede adjuntar un nuevo documento'
                    : 'Adjunte documento para modificaciones.'
                "
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                class="primary mt-2"
                @click.stop="subirModificativa"
                :disabled="!form.file"
              >
                Enviar
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="mt-0" v-if="subido">
            <v-col>
              <v-btn
                class="secondary"
                @click="
                  (documento_visible = true),
                    $refs.pdf.obtenerArchivo(ruta, 'logAuditoriaLotes')
                "
                >Ver último adjunto
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card style="height: 30vh">
          <v-card-title>
            <v-icon class="mr-2">mdi-clipboard-account</v-icon>
            Administradores de contrato
          </v-card-title>
          <v-card-text style="overflow-y: auto; max-height: calc(28vh - 64px)">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(contrato, index) in contratoOrden"
                :key="index"
              >
                <v-expansion-panel-header>
                  Contrato: {{ contrato.numero }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list>
                    <v-list-item-group>
                      <template v-if="contrato.emails_administradores">
                        <v-list-item
                          v-for="(
                            email, emailIndex
                          ) in contrato.emails_administradores.split(', ')"
                          :key="emailIndex"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{ email }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-else>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              >No hay administradores de
                              contrato</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card class="mt-7 pa-8">
      <div v-if="mostrarInformacion">
        <div>
          <v-row>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Código de proceso</p>
              <p>{{ contratoOrden[0]?.codigo_proceso }}</p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Nombre de proceso</p>
              <p>{{ contratoOrden[0]?.nombre_proceso }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Modalidad de compra</p>
              <p>{{ contratoOrden[0]?.modalidad_compra }}</p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Fecha y hora de contratación</p>
              <p>
                {{
                  moment(contratoOrden[0]?.fecha_hora_contratacion).format(
                    "DD/MM/YYYY hh:mm a"
                  )
                }}
              </p>
            </v-col>
          </v-row>
        </div>

        <v-divider class="mb-8 mt-5" />
        <v-card-title>
          <v-icon class="mr-2"> mdi-note-text</v-icon>
          Información de contratos
        </v-card-title>
        <data-table-component
          :headers="headers"
          :items="contratoOrden"
          :inhabilitar_paginacion="true"
          :tiene_paginacion="false"
        >
          <template v-slot:[`item.monto_adjudicado`]="{ item }">
            $
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(item.monto_adjudicado)
            }}
          </template>

          <template v-slot:[`item.fecha_hora_contratacion`]="{ item }">
            {{
              moment(item?.fecha_hora_contratacion).format("DD/MM/YYYY hh:mm a")
            }}
          </template>

          <template v-slot:[`item.proveedor_adjudicado`]="{ item }">
            <strong>Nombre:</strong> {{ item.nombre }} -
            <strong>Nombre comercial:</strong> {{ item.nombre_comercial }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="abrirModalDetalleEtapa(item)"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                >
                  mdi-comment-eye
                </v-icon>
              </template>
              <span> Ver detalle de contrato </span>
            </v-tooltip>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="abrirModalDocumentosContrato(item)"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                >
                  mdi-text-box-search
                </v-icon>
              </template>
              <span> Ver documentos del contrato </span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :disabled="!subido || tieneDocumentoEnContrato(item)"
                  @click="abrirModal(item)"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                >
                  mdi-file-plus-outline
                </v-icon>
              </template>
              <span> Cargar documento </span>
            </v-tooltip>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :disabled="!subido"
                  @click="abrirModalEliminarEtapa(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-delete-sweep
                </v-icon>
              </template>
              <span> Eliminar contrato </span>
            </v-tooltip>
          </template>
        </data-table-component>
      </div>
      <div class="d-flex justify-center" v-else>
        <p class="mb-0 font-weight-bold">
          El proceso de compra aún no posee contratos activos
        </p>
      </div>
    </v-card>

    <modalDocumentosContratos
      :abrirModalDocumentos="modal_documentos"
      :documentos="documentosData"
      @cerrar="cerrarModalDocumentosContrato"
      @cerrarModalActualizarDocumento="cerrarModalyActualizarDocumentosContrato"
      :archivoSubido="subido"
      :idProceso="id_proceso"
    />
    <modalDetalleEtapa
      :abrirModalDetalle="modal_detalle"
      :dataEtapa="etapasContratoById"
      :dataContratoDetalle="contratoDetalleById"
      @cerrar="cerrarModalDetalle"
      @cerrarModalActualizar="cerrarModalyActualizar"
      @actualizarModalAdjudicado="actualizarModalMontoAdjudicado"
      :archivoSubido="subido"
      :idProceso="id_proceso"
    />
    <ConfirmationDialog
      :show="modal_eliminar"
      title="¿Deseas eliminar el contrato seleccionado?"
      message="El cambio es irreversible, eliminará toda la información relacionada al contrato"
      btnConfirmar="Continuar"
      @close="modal_eliminar = false"
      @confirm="eliminarEtapasContrato"
    />

    <ModalDocumentoContrato
      :is-open="modalDocumento"
      @close="modalDocumento = false"
      @on-save="guardarDocumentoContrato"
      :tipo-contrato="tipoContrato"
      :is-loading="guardadoDocumento.isLoading"
    />

    <PdfModalClienteViejo :permitirDescargar="true" ref="pdf" />
  </section>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import PdfModalClienteViejo from "@/components/PdfModalClienteViejo.vue";
import moment from "moment";
import { convertToFormData } from "@/utils/data";
import modalDetalleEtapa from "./modalDetalleEtapa.vue";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import modalDocumentosContratos from "./modalDocumentosContratos.vue";
import ModalDocumentoContrato from "../../Etapas/ModalDocumentoContrato.vue";
import {
  createLoadable,
  setLoadableResponse,
  toggleLoadable,
} from "@/utils/loadable";

export default {
  name: "EliminarEtapasContrato",
  components: {
    DataTableComponent,
    PdfModalClienteViejo,
    modalDetalleEtapa,
    ConfirmationDialog,
    modalDocumentosContratos,
    ModalDocumentoContrato,
  },
  data: () => ({
    form: {
      file: null,
    },
    filters: {
      correlativo: null,
    },
    subido: false,
    ruta: null,
    headers: [
      {
        text: "Correlativo",
        value: "numero",
        align: "center",
      },
      {
        text: "Número interno",
        value: "numero_interno",
        align: "center",
      },
      {
        text: "Nombre",
        value: "nombre_contrato",
        align: "center",
      },
      {
        text: "Fecha contratación",
        value: "fecha_hora_contratacion",
        align: "center",
      },
      {
        text: "Monto adjudicado",
        value: "monto_adjudicado",
        align: "center",
      },
      {
        text: "Versión",
        value: "version_seguimiento",
        align: "center",
      },
      {
        text: "Proveedor adjudicado",
        value: "proveedor_adjudicado",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
      },
    ],
    contratoOrden: [],
    etapasContrato: [],
    // modal de detalle
    contratoDetalleById: {},
    etapasContratoById: [],
    modal_detalle: false,
    // modal para eliminar
    modal_eliminar: false,
    id_contrato_selected: null,
    timer: null,
    // modal documentos
    modal_documentos: false,
    documentosData: [],
    documentosContratoSelected: [],
    //Modal para cargar documentos
    modalDocumento: false,
    contratoSeleccionado: null,
    tipoContrato: createLoadable([]),
    guardadoDocumento: createLoadable([]),
    verificar: createLoadable([]),
  }),
  computed: {
    mostrarInformacion() {
      return Object.keys(this.contratoOrden).length > 0;
    },
    id_proceso() {
      return +this?.$route?.params?.idProceso || 0;
    },
  },
  methods: {
    tieneDocumentoEnContrato(item) {
      const contratos = this.verificar.data.contratos.find(
        (documento) => documento.idContrato === item.id_contrato
      );
      return contratos ? contratos.tieneDocumentoCargado : true;
    },
    // subir archivo de modificativa
    async subirModificativa() {
      if (this.form.file === null) {
        return this.pushAppMessage({
          message: "Debe adjuntar el documento de modificativa",
          type: "warning",
        });
      }
      const formData = convertToFormData(this.form);
      const id_modificativa = 8; // Corresponde a tipo de modificativa eliminar etapas por contrato

      await this.services.ModificativasProcesosServices.subirModificativa(
        this.id_proceso,
        id_modificativa,
        formData
      );
      this.form.file = null;
      await this.obtenerModificativa();
    },

    async obtenerModificativa() {
      const id_modificativa = 8;
      const { data } =
        await this.services.ModificativasProcesosServices.validarModificativa(
          this.id_proceso,
          id_modificativa
        );
      this.subido = !!data?.subida;
      this.ruta = data?.modificacion?.adjunto;
      this.$emit("validar", this.subido);
    },

    async getContratoByCorrelativo() {
      const { data } =
        await this.services.EliminarEtapasContratoServices.obtenerContratoByCorrelativo(
          this.id_proceso,
          this.filters
        );
      if (data) {
        this.contratoOrden = data;
      }
    },

    async abrirModalDetalleEtapa(item) {
      const { data } =
        await this.services.EliminarEtapasContratoServices.obtenerEtapasByContratoid(
          item.id_contrato
        );
      if (data) {
        this.contratoDetalleById = data;
        this.etapasContratoById = data.etapas;
      }
      this.modal_detalle = true;
    },
    cerrarModalDetalle() {
      this.modal_detalle = false;
    },
    cerrarModalyActualizar() {
      this.modal_detalle = false;
      this.getContratoByCorrelativo();
    },
    actualizarModalMontoAdjudicado() {
      this.cerrarModalyActualizar();
    },

    async abrirModalDocumentosContrato(item) {
      this.documentosContratoSelected = item;
      this.obtenerDocumentosContrato(
        this.documentosContratoSelected.id_contrato
      );
      this.modal_documentos = true;
    },
    cerrarModalDocumentosContrato() {
      this.modal_documentos = false;
    },
    cerrarModalyActualizarDocumentosContrato() {
      this.modal_detalle = false;
      this.obtenerDocumentosContrato(
        this.documentosContratoSelected.id_contrato
      );
      this.verificarDocumentosContrato();
    },
    async obtenerDocumentosContrato(idContrato) {
      const { data } =
        await this.services.EliminarEtapasContratoServices.obtenerDocumentosContrato(
          idContrato
        );
      if (data) {
        this.documentosData = data;
      }
    },

    async abrirModalEliminarEtapa(item) {
      this.id_contrato_selected = item.id_contrato;
      this.modal_eliminar = true;
    },
    async eliminarEtapasContrato() {
      const { data } =
        await this.services.EliminarEtapasContratoServices.eliminarContratoById(
          this.id_contrato_selected,
          this?.$route?.params?.idProceso
        );
      if (data) {
        this.temporalAlert({
          show: true,
          message: data.message,
          type: "success",
        });
        this.show_modal = false;
        this.modal_eliminar = false;
        this.getContratoByCorrelativo();
      }
    },

    validateFile() {
      const file = this.form.file;
      if (file && file.type !== "application/pdf") {
        this.form.file = null;
        return this.pushAppMessage({
          message: "El documento debe ser pdf",
          type: "warning",
        });
      }
    },
    async verificarDocumentosContrato() {
      toggleLoadable(this.verificar);
      const { data } =
        await this.services.CertificacionFondo.verificarContratosProcesoCompra(
          this.$route.params.idProceso
        );
      setLoadableResponse(this.verificar, data);
    },
    async listarTipoContrato() {
      toggleLoadable(this.tipoContrato);
      const { data } =
        await this.services.CertificacionFondo.obtenerTipoDocumento();
      setLoadableResponse(this.tipoContrato, data);
    },

    abrirModal(data) {
      this.contratoSeleccionado = data.id_contrato;
      this.modalDocumento = true;
      this.listarTipoContrato();
    },

    async guardarDocumentoContrato(item) {
      const payload = {
        ...item,
        idProceso: this.$route?.params?.idProceso,
      };
      const formData = convertToFormData(payload);
      toggleLoadable(this.guardadoDocumento);
      const { data } = await this.services.EliminarEtapasContratoServices.guardarDocumento(
        this.contratoSeleccionado,
        formData
      );
      setLoadableResponse(this.guardadoDocumento, data, {
        showAlertOnSuccess: true,
      });

      this.verificarDocumentosContrato(this.$route.params.idProceso);
      this.modalDocumento = false;
    }
  },
  created() {
    this.getContratoByCorrelativo();
    this.obtenerModificativa();
    this.verificarDocumentosContrato();
  },
};
</script>

<template>
    <v-card>
        <v-card-text>
            <ValidarEdicion
                :id_proceso="idProceso"
                :id_modificativa="10"
                @validar="validar" 
            />
    
            <div v-if="validado" class="mt-4">
                <EtapasProcesoImpugnacionesComponent
                    permitir-edicion-etapas-finalizadas
                    permitir-edicion-fecha
                />
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import EtapasProcesoImpugnacionesComponent from '@/views/ProcesoCompraDoc/RecursosRevision/components/EtapasProcesoImpugnacionesComponent.vue';
import ValidarEdicion from '../ValidarEdicion.vue';

export default {
    name: 'ModificarEtapas',
    components: { EtapasProcesoImpugnacionesComponent, ValidarEdicion },
    data: () => ({
        validado: false,
    }),
    computed: {
		idProceso() {
			return Number(this?.$route?.params?.idProceso || 0);
		},
	},
    methods: {
        validar(valor) {
			this.validado = valor;
		},
    },
}
</script>
<template>
  <v-card>
    <v-card-actions>
      <v-row>
        <v-col>
          <ValidarEdicion
            :id_proceso="+this.id_proceso"
            :id_modificativa="9"
            @validar="validar" />
        </v-col>
      </v-row>
    </v-card-actions>

    <v-divider />

    <data-table-component
      :headers="headers"
      :items="especificasData"
      :inhabilitar_paginacion="true"
      :tiene_paginacion="false">

      
      <template v-slot:[`item.created_at`]="{ item }">
        {{ moment(item?.created_at).format("DD/MM/YYYY hh:mm a") }}
      </template>

      <template v-slot:[`item.paso`]="{ item }">
        <v-chip
          label
          :color="item.PasoSolicitudEstados[0]?.SolicitudPaso.color_estado"
        >
          {{ item.PasoSolicitudEstados[0]?.SolicitudPaso.nombre || "" }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="abrirModalDetalleEspecifica(item)"
              v-bind="attrs"
              v-on="on"
              class="mr-2" 
              >
              mdi-information
            </v-icon>
          </template>
          <span> Detalle de específica </span>
        </v-tooltip>

      </template>
    </data-table-component>

    <modalDetalleEspecifica
      :abrirModalDetalleEspecifica = "modal_insumos"
      :insumos = "insumosDataEspecifica"
      :idProceso="id_proceso"
      :archivoSubido="validado"
      @cerrar="cerrarModalDetalleEspecifica"
      @actualizarListaEspecifica="refreshEspecificas"
    />

  </v-card>
</template>
<script>
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import ValidarEdicion from "../ValidarEdicion.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import modalDetalleEspecifica from "./modalDetalleEspecifica.vue";

export default {
	name: "ListadoSolicitudesEspecificas",
	components: {
    ValidarEdicion,
    ConfirmationDialog,
    DataTableComponent,
    modalDetalleEspecifica,
  },
	computed: {
		id_proceso() {
			return this?.$route?.params?.idProceso || 0;
		},
	},
	data: () => ({
		validado: false,
    especificasData: [],
    // modal para abrir insumos
    modal_insumos: false,
    insumosDataEspecifica: {},
    headers: [
      {
        text: "Código de solicitud",
        value: "codigo",
        align: "center",
      },
      {
        text: "Unidad solicitante",
        value: "Unidad.nombre",
        align: "center",
      },
      {
        text: "Fecha de creación",
        value: "created_at",
        align: "center",
      },
      {
        text: "Estado de solicitud",
        value: "paso",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
      },
    ],
	}),
	methods: {
		validar(valor) {
			this.validado = valor;
		},
    async obtenerListaEspecificas() {
      const { data } = await this.services.EliminarEspecificasProcesoServices.obtenerEspecificas(this.id_proceso);
      if (data) {
        this.especificasData = data;
      }
    },

    async refreshEspecificas() {
      this.obtenerListaEspecificas();
      this.modal_insumos = false;
    },
    
    async abrirModalDetalleEspecifica(item){
      const { data } = await this.services.EliminarEspecificasProcesoServices.obtenerInsumosEspecifica(item.id);
      if (data) {
        this.insumosDataEspecifica = data;
      }
      this.modal_insumos = true;
    },

    cerrarModalDetalleEspecifica() {
      this.modal_insumos = false;
    },
	},
	async created() {
    this.obtenerListaEspecificas();
	},
};
</script>
<style>
.highlight-row {
	background-color: #ecb36d;
}
</style>